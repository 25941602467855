import React from 'react'
// import { Footer } from '@pmndrs/branding'

// import Footer from './components/Footer'
import linkedin from './assets/icon-linkedin.png'

export default function Overlay({ ready, clicked, setClicked }) {
  return (
    <>
      <div className={`fullscreen bg ${ready ? 'ready' : 'notready'} ${clicked && 'clicked'}`}>
        <div onClick={() => ready && setClicked(true)}>{!ready ? 'loading' : 'click to continue'}</div>
      </div>
      <footer className="footer">
        <div className="site-wrapper">
          <a className="link" href="https://www.linkedin.com/in/crismartinez1/" target="_blank" rel="noreferrer">
            <img src={linkedin} alt="LinkedIn logo"/>
          </a>
        </div>
      </footer>
    </>
  )
}
