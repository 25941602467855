import * as THREE from 'three'
import React, { Suspense, useState, useEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Reflector, useTexture } from '@react-three/drei'

import Overlay from './Overlay'
import TextLine from './components/TextLine'

import SurfaceImperfections003_1K_var1 from './assets/SurfaceImperfections003_1K_var1.jpg'
import SurfaceImperfections003_1K_normal from './assets/SurfaceImperfections003_1K_Normal.jpg'



// function VideoText1({ clicked, ...props }) {
//   useEffect(() => void (clicked), [clicked])
//   return (
//     <>
//       <Text font={inter} color={'#fff'} fontSize={1} letterSpacing={-0.06} {...props}>
//         Cristian is a
//       </Text>
//     </>
//   )
// }


const copy = [
  "Cristian is a",
  "front end",
  "developer."
]

// function Character({clicked, ...props }) {
  
//   useEffect(() => void (clicked), [clicked])
//   const charRef = useRef()
//   const [hovered, setHovered] = useState(false)
//   useEffect(() => {
//     console.log(hovered)
//     if (hovered) {
//       charRef.current.material.color.set( colors[Math.floor(Math.random() * 4) + 1])
//     } else {
//       charRef.current.material.color.set( 0xffffff )
//     }
//   }, [hovered])

//   return (
//     <>
//       <Text ref={charRef} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)} font={inter} color={'#fff'} fontSize={1} letterSpacing={-0.06} {...props}>
//         Cristian is a
//       </Text>
//     </>
//   )
// }

// function VideoText2({ clicked, ...props }) {
//   useEffect(() => void (clicked), [clicked])
//   const textRef = useRef()

//   const [hovered, setHovered] = useState(false)
//   useEffect(() => {
//     console.log(hovered)
//     if (hovered) {
//       textRef.current.material.color.setHex( 0xbdc73c )
//     } else {
//       textRef.current.material.color.setHex( 0xffffff )
//     }
//   }, [hovered])

//   return (
//     <>
//       <Text ref={textRef} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)} font={inter} color={'#fff'} fontSize={1} letterSpacing={-0.06} {...props}>
//         front end
//       </Text>
//     </>
//   )
// }

// function VideoText3({ clicked, ...props }) {
//   useEffect(() => void (clicked), [clicked])
//   return (
//     <>
//       <Text font={inter} color={'#fff'} fontSize={1} letterSpacing={-0.06} {...props}>
//         developer.
//       </Text>
//     </>
//   )
// }

export function Ground() {
  const [floor, normal] = useTexture([SurfaceImperfections003_1K_var1, SurfaceImperfections003_1K_normal])
  return (
    <Reflector blur={[400, 100]} resolution={512} args={[10, 10]} mirror={0.5} mixBlur={6} mixStrength={1.5} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
      {(Material, props) => <Material color="#a0a0a0" metalness={0.4} roughnessMap={floor} normalMap={normal} normalScale={[2, 2]} {...props} />}
    </Reflector>
  )
}

export default function App() {
  const [clicked, setClicked] = useState(false)
  const [ready, setReady] = useState(false)
  const store = { clicked, setClicked, ready, setReady }
  return (
    <>
      <Canvas concurrent gl={{ alpha: false }} pixelRatio={[1, 1.5]} camera={{ position: [0, 3, 100], fov: 15 }}>
        <color attach="background" args={['black']} />
        <fog attach="fog" args={['black', 15, 20]} />
        <Suspense fallback={null}>
          {/* <group position={[0, -1, 0]}> */}
            {/* <Character {...store} position={[0, 2.2, -2]}  /> */}
            {/* <VideoText1 {...store} position={[0, 2.2, -2]} /> */}
            {/* <VideoText2 {...store} position={[0, 1.35, -2]} />
            <VideoText3 {...store} position={[0, .5, -2]} /> */}
            <TextLine {...store} copy={copy} />
            {/* <Ground /> */}
          {/* </group> */}
          <ambientLight intensity={0.5} />
          <spotLight position={[0, 10, 0]} intensity={0.3} />
          <directionalLight position={[-20, 0, -10]} intensity={0.7} />
          <Intro start={ready && clicked} set={setReady} />
        </Suspense>
      </Canvas>
      <Overlay {...store} />
        {/* <Footer/> */}
    </>
  )
}

function Intro({ start, set }) {
  const [vec] = useState(() => new THREE.Vector3())
  useEffect(() => setTimeout(() => set(true), 500), [])
  return useFrame((state) => {
    if (start) {
      state.camera.position.lerp(vec.set(state.mouse.x * 5, 3 + state.mouse.y * 2, 15), 0.05)
      state.camera.lookAt(0, .2, 0)
    }
  })
}
