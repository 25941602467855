// import * as THREE from 'three'
import React, { useEffect, useState, useRef } from 'react'
import { Text } from '@react-three/drei'

import inter from '../assets/Inter-Bold.woff'

const colors = {
  1: '#0aff99',
  2: '#2176FF',
  3: '#f2f230',
  4: 'red',
  5: '#2d00f7',
}

// const colors = {
//   1: '#2176FF',
//   2: 'red',
//   3: '#c4fff9',
//   4: '#FFEA00',
//   5: '#89fc00',
//   // 6: '#FFB700',
//   // 7: '#FFC300',
//   // 8: '#FFDD00',
//   // 9: '#FFEA00',
// }
// const colors = {
//   1: '#FF7B00',
//   2: '#FF8800',
//   3: '#FF9500',
//   4: '#FFA200',
//   5: '#FFAA00',
//   // 6: '#FFB700',
//   // 7: '#FFC300',
//   // 8: '#FFDD00',
//   // 9: '#FFEA00',
// }

export default function Character({clicked, char, ...props }) {
  
  useEffect(() => void (clicked), [clicked])
  const charRef = useRef()
  const [hovered, setHovered] = useState(false)
  useEffect(() => {
    // console.log('hovered')
    if (hovered) {
      let num = Math.floor(Math.random() * 5) + 1
      // console.log(num);
      charRef.current.material.color.set(colors[num] )
    } else {
      charRef.current.material.color.set( 0xffffff )
    }
  }, [hovered])

  return (
    // <>
      <Text ref={charRef} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)} font={inter} color={'#fff'} fontSize={1} letterSpacing={-0.01} {...props}>
        {char}
      </Text>
    // </>
  )
}