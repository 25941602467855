import React from 'react'

import Character from './Character'
import {Ground} from '../App'

// const colors = {
//   1: '#a9e5bb',
//   2: '#5299d3',
//   3: '#f7b05b',
//   4: '#e4572e'
// }
// const colors = {
//   0: '#a9e5bb',
//   1: '#5299d3',
//   2: '#f7b05b',
//   3: '#e4572e'
// }

const spacing = {
  one: {
    a0: [-2.3, 2.2, -2],
    a1: [-1.8, 2.2, -2],
    a2: [-1.5, 2.2, -2],
    a3: [-1.1, 2.2, -2],
    a4: [-0.65, 2.2, -2],
    a5: [-0.34, 2.2, -2],
    a6: [0.05, 2.2, -2],
    a7: [.6, 2.2, -2],
    a8: [1.3, 2.2, -2],
    a9: [1.3, 2.2, -2],
    a10: [1.65, 2.2, -2],
    a11: [1.65, 2.2, -2],
    a12: [2.4, 2.2, -2],
  
  },
  two: {
    a0 : [1 + -2.87, 1.35, -2],
    a1 : [1 + -2.5, 1.35, -2],
    a2 : [1 + -2.05, 1.35, -2],
    a3 : [1 + -1.5, 1.35, -2],
    a4 : [1 + -1.06, 1.35, -2],
    a5 : [1 + -.08, 1.35, -2],
    a6 : [1 + -.3, 1.35, -2],
    a7 : [1 + .25, 1.35, -2],
    a8 : [1 + .82, 1.35, -2],
  
  },
  three: {
    a0: [.45 + -2.3, 0.5, -2],
    a1: [.45 + -1.75, 0.5, -2],
    a2: [.45 + -1.25, 0.5, -2],
    a3: [.45 + -.75, 0.5, -2],
    a4: [.45 + -.4, 0.5, -2],
    a5: [.45 + -0.04, 0.5, -2],
    a6: [.45 + 0.5, 0.5, -2],
    a7: [.45 + 1.05, 0.5, -2],
    a8: [.45 + 1.47, 0.5, -2],
    a9: [.45 + 1.65, 0.5, -2]
  }

}

export default function TextLine({clicked, copy, ...props }) {
  return (
    <>
      <group position={[0, -1, 0]}>

        {
         copy.map((line, index) => {
            if (index === 0) {
              return (
                line.split('')
                .map((char, index) => {
                  return <Character position={spacing.one[`a${index}`]} char={char} key={Math.floor(Math.random()) + index}/>
                }) 
              )
            }
            else if (index === 1) {
              return (
                line.split('')
                .map((char, index) => {
                  return <Character position={spacing.two[`a${index}`]} char={char} key={Math.floor(Math.random()) + index}/>
                })
              )

            }
            else {
              return (
                line.split('')
                .map((char, index) => {
                  return <Character position={spacing.three[`a${index}`]} char={char} key={Math.floor(Math.random()) + index}/>
                  
                })
              )
            }
          })
        }
        <Ground /> 
      </group>
    </>
  )
}